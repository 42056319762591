import { NavigateAction, Action } from "../../types";
import apiService from "../../services/apiService";
import {
  modelsLoadingSuccess,
  modelsLoadingStart,
} from "../../store/reducers/models/actions";
import getModelsParamsFromRoute from "../../utils/getModelsParamsFromRoute";

type Payload = {
  page?: number;
  limit?: number;
  offset?: number;
  gender?: string;
  pattern?: string;
  tag?: string[];
  names?: string[];
  replace?: boolean;
};

const modelsListAction: Action<Payload> = (
  context,
  { replace, page, ...payload }
) =>
  context
    .dispatch(modelsLoadingStart())
    .then(() =>
      context.service(apiService, { method: "models", query: payload })
    )
    .then(({ payload, limit, total }) =>
      context.dispatch(
        modelsLoadingSuccess({ list: payload, replace, page, limit, total })
      )
    );

export const navigateModelsListAction: NavigateAction = (
  context,
  { route, params }
) =>
  context.action(modelsListAction, {
    ...getModelsParamsFromRoute(route),
    pattern: params.pattern,
    ...(params.limit ? { limit: Number(params.limit) } : {}),
    replace: true,
  });

export default modelsListAction;
