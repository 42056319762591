import React, { Fragment } from "react";
import { BsThreeDots } from "react-icons/bs";
import cn from "classnames";
import { Route } from "router6";
import { Link } from "router6-react";

import s from "./styles.css";

type Props = {
  perPage: number;
  queryKey?: string;
  total: number;
  route: Route;
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
const Pagination = ({ queryKey = "page", route, total, perPage }: Props) => {
  const { [queryKey]: page = 1 } = route.query;
  const pagesCount = Math.ceil(total / perPage);
  const currentPage = Number(page);
  const points = [0, currentPage, pagesCount];
  const visibleArea = 3;
  const pagesToRender: number[] = points
    .reduce(
      (result, point) => [
        ...result,
        ...[...new Array(visibleArea)].reduce(
          (acc, _, i) => [...acc, point - i, point, point + i],
          []
        ),
      ],
      []
    )
    .filter((number) => number > 0 && number <= pagesCount)
    .filter(onlyUnique)
    .sort((a, b) => a - b);

  return (
    pagesToRender.length > 1 && (
      <div className={s.root}>
        {pagesToRender.map((number, index) => {
          const prevNumber = index > 0 ? pagesToRender[index - 1] : undefined;

          return (
            <Fragment key={number}>
              {prevNumber && number - prevNumber > 1 ? (
                <span className={s.gap}>
                  <BsThreeDots />
                </span>
              ) : null}
              <Link
                key={number}
                to={route.name}
                params={route.params}
                query={{ ...route.query, [queryKey]: String(number) }}
              >
                <button
                  className={cn(s.button, {
                    [s.current]: number === currentPage,
                  })}
                >
                  {number}
                </button>
              </Link>
            </Fragment>
          );
        })}
      </div>
    )
  );
};

export default Pagination;
