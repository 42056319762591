import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../connect";
import seoSelector from "../../store/reducers/seo/selectors/seoSelector";
import s from "./styles.css";

const SeoBlockTop = () => {
  const { header, subheader } = useAppSelector(seoSelector);
  const headerRef = useRef<HTMLHeadingElement>();
  const subheaderRef = useRef<HTMLParagraphElement>();
  const [height, setHeight] = useState<number>(undefined);

  useEffect(() => {
    let nextHeight = 0;

    if (headerRef.current) {
      nextHeight += 20 + headerRef.current.getBoundingClientRect().height;
    }
    if (subheaderRef.current) {
      nextHeight += 20 + subheaderRef.current.getBoundingClientRect().height;
    }

    setHeight(nextHeight);
  }, [header, subheader]);

  return (
    <div className={s.root} style={{ height }}>
      {header && (
        <h1 className={s.header} ref={headerRef}>
          {header}
        </h1>
      )}
      {subheader && (
        <p className={s.subheader} ref={subheaderRef}>
          {subheader}
        </p>
      )}
    </div>
  );
};

export default SeoBlockTop;
