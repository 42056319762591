import React from "react";
import { Route } from "router6";

import ThumbsListPagination from "../../components/ThumbsListPagination";
import ThumbsListInfinite from "../../components/ThumbsListInfinite";
import getModelsListBy from "../../store/reducers/models/selectors/getModelsListBy";
import getModelsParamsFromRoute from "../../utils/getModelsParamsFromRoute";
import getListingType from "../../store/reducers/application/selectors/getListingType";
import { ListingType } from "../../store/reducers/application/types";
import { useAppSelector } from "../../connect";
import MainTagsList from "../../components/MainTagsList";
import SeoBlockTop from "../../components/SeoBlockTop";
import s from "./styles.css";

const HomePage = ({ route }: { route: Route }) => {
  const listingType = useAppSelector(getListingType);
  const ThumbsComponent =
    listingType === ListingType.InfiniteScroll
      ? ThumbsListInfinite
      : ThumbsListPagination;

  return (
    <>
      <SeoBlockTop />
      <MainTagsList />
      <div className={s.root}>
        <ThumbsComponent
          selector={getModelsListBy(getModelsParamsFromRoute(route))}
        />
      </div>
    </>
  );
};

export default HomePage;
