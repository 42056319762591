import { Route } from 'router6/src';

import { sectionsToGender } from './sectionToGender';

const PER_PAGE = 32;

export default (route: Route) => {
  const { section, tag = '' }: { section?: string; tag?: string } =
    route.params;
  const { page = 1 }: { page?: number } = route.query;

  return {
    page,
    offset: (page - 1) * PER_PAGE,
    limit: PER_PAGE,
    gender: sectionsToGender(section),
    tag: tag.split('+').filter(Boolean),
  };
};
