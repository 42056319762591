import React from 'react';
import { RiLoader3Line } from 'react-icons/ri';

import s from './styles.css';

const Loader = ({
  size = 100,
  overlay = true,
}: {
  size?: number;
  overlay?: boolean;
}) => (
  <div className={overlay ? s.root : null}>
    <div
      className={s.spin}
      style={{ fontSize: size, width: size, height: size }}
    >
      <RiLoader3Line />
    </div>
  </div>
);

export default Loader;
