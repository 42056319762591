import React, { useRef } from "react";

import { Model } from "../../store/reducers/models/types";
import { State } from "../../store/types";
// import modelsNextPageAction from '../../actions/models/nextPage';
import useIntersection from "../../utils/useIntersection";
import ThumbsList from "../ThumbsList";
import Loader from "../Loader";

import s from "./styles.css";
import connect, { useAppSelector } from "../../connect";

type Selector = (state: State) => Model[];
const stateSelector = ({ models: { finished, loading } }: State) => ({
  finished,
  loading,
});
const ThumbsListInfinite = ({
  selector,
  loadMore,
}: {
  selector: Selector;
  loadMore: () => void;
}) => {
  const { loading, finished } = useAppSelector(stateSelector);
  const loader = useRef<HTMLDivElement>(null);

  // useIntersection(loader, loadMore);

  return (
    <div>
      <ThumbsList selector={selector} />

      <div className={s.loader} ref={loader}>
        {loading && <Loader size={50} />}
        {finished && <div>That's it!</div>}
      </div>
    </div>
  );
};

export default connect(null, ({ action }) => ({ loadMore: () => {} }))(
  ThumbsListInfinite
);
