import { createSelector } from 'reselect';

import getModelsList from './getModelsList';

const getModelsListBy = ({ gender, tag }: { gender?: string; tag: string[] }) =>
  createSelector(getModelsList, (list) =>
    list.filter(
      (model) =>
        (!gender || model.tags?.some(({ name }) => gender === name)) &&
        (!tag.length || model.tags?.some(({ name }) => tag.includes(name))),
    ),
  );

export default getModelsListBy;
