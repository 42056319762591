import React from "react";
import { useRouter } from "router6-react";

import { Model } from "../../store/reducers/models/types";
import { State } from "../../store/types";
import ThumbsList from "../ThumbsList";
import Pagination from "../Pagination";
import Loader from "../Loader";
import { useAppSelector } from "../../connect";
import { createSelector } from "reselect";

type Selector = (state: State) => Model[];

const metaSelector = createSelector(
  ({ models }) => models,
  ({ total, loading }) => ({ total, loading })
);

const ThumbsListPagination = ({ selector }: { selector: Selector }) => {
  const { currentRoute } = useRouter();
  const { total, loading } = useAppSelector(metaSelector);

  return (
    <div>
      <ThumbsList selector={selector}>
        {loading ? <Loader size={80} /> : null}
      </ThumbsList>
      <Pagination total={total} route={currentRoute} perPage={32} />
    </div>
  );
};

export default ThumbsListPagination;
